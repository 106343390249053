<template>
  <main class="container py-4 px-3 px-md-4 bg-neutral-dark">
    <section class="row justify-content-between g-4">
      <div
        class="col-12 col-md-8 cursor-pointer animated"
        @click="$router.push({ name: 'CreateProject1' })"
      >
        <div class="box position-relative">
          <img
            class="position-absolute top-0 left-0 w-100 h-100 img-cover"
            :src="require('@/assets/icons/Home/build_project.jpg')"
          />
          <div
            class="position-absolute top-0 left-0 text-container d-flex flex-column justify-content-end align-items-start"
          >
            <span class="text-neutral font-weight-300 header-text mb-2">
              Build your project now
            </span>
            <span class="text-neutral text-left font-weight-300 body-text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </span>
          </div>
        </div>
      </div>

      <div
        class="col-12 col-md-4 cursor-pointer animated"
        @click="$router.push({ name: 'Developers' })"
      >
        <div class="box position-relative">
          <img
            class="position-absolute top-0 left-0 w-100 h-100 img-cover"
            :src="require('@/assets/icons/Home/find_developer.jpg')"
          />
          <div
            class="position-absolute top-0 left-0 text-container d-flex flex-column justify-content-end align-items-start"
          >
            <span class="text-neutral font-weight-300 header-text mb-2">
              Find your developer
            </span>
            <span class="text-neutral text-left font-weight-300 body-text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            </span>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-7 animated">
        <div class="box position-relative">
          <img
            class="position-absolute top-0 left-0 w-100 h-100 img-cover"
            :src="require('@/assets/icons/Home/gift.jpg')"
          />
          <div
            class="position-absolute top-0 left-0 text-container d-flex flex-column justify-content-end align-items-start"
          >
            <span class="text-neutral font-weight-300 header-text mb-2">
              Looking for the perfect gift?
            </span>
            <span class="text-neutral text-left font-weight-300 body-text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </span>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-5 cursor-pointer animated">
        <a
          href="https://space7-egypt-staging.herokuapp.com/shop"
          target="_blank"
        >
          <div class="box position-relative">
            <img
              class="position-absolute top-0 left-0 w-100 h-100 img-cover"
              :src="require('@/assets/icons/Home/store.png')"
            />
            <div
              class="position-absolute top-0 left-0 text-container d-flex flex-column justify-content-end align-items-start"
            >
              <span class="text-neutral font-weight-300 header-text mb-2">
                Space 7 Store
              </span>
              <span class="text-neutral text-left font-weight-300 body-text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </span>
            </div>
          </div>
        </a>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: "StoreFront",
  computed: {
    width: function () {
      return window.innerWidth;
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  height: 40vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  line-height: 1.3;
  overflow: hidden;
  border: 1.5px solid var(--primary);
}

.animated {
  animation: FadeIn 1000ms ease-in-out;
  animation-fill-mode: both;
}

@keyframes FadeIn {
  0% {
    opacity: 0;
    transform: scale(0.1);
  }

  60% {
    transform: scale(1);
  }

  100% {
    opacity: 1;
  }
}

@for $i from 1 through 10 {
  .animated:nth-child(#{$i}n) {
    animation-delay: #{$i * 0.25}s;
  }
}

.text-container {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: linear-gradient(rgba(255, 255, 255, 0), rgba(0, 30, 66, 0.9) 70%);
  padding: 15px 20px;
}

.left-1 {
  width: 70%;
}

.left-2 {
  width: 70%;
}
</style>
